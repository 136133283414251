.navbar {
    padding-top: 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-end;
}
.navbar-item {
    padding: 12px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: space-around;
}

.divider {
    margin: 18px;
}

.frame-parent,
.frame-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 18px;
}
.union-wrapper {
    background-color: red;
}
.union-container {
    background-color: black;
}
.union-wrapper,
.union-container {
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
}
.product {
    margin: 12px;
    font-size: large;
    font-weight: bold;
    word-break: break-all;
    overflow: hidden;
}
