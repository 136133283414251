.image {
    background-size: cover;
    width: 100%;
}

footer {
    background-color: black;
    width: 100%;
    height: 200px;
    border-top: 1px solid #c4c4c4;
    padding-top: 15px;
    color: #808080;
    font-size: 24px;
}

footer a {
    display: inline-block;
    color: #808080;
    font-size: 12px;
}
